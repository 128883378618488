import {FC} from "react";
import {Link} from "react-router-dom";

const Footer: FC = () => {
    return <>
        <p className='text-center'>&copy; Copyright 2024 Zachary Baker</p>
        <p className='text-center'>Images courtesy Unsplash. <Link to={'/credits'} className="link-light">Click here</Link> for more info.</p>
    </>
}

export default Footer;
