import React, {useEffect} from "react";
import Footer from "../components/Footer";
import code from '../assets/code.jpg'
import website from '../assets/website.jpg'
import { Link } from "react-router-dom";

const Home = () => {
    useEffect(() => {
        document.title = "Home | Zach Baker";
    }, []);

    return <>
        <div className='d-flex' style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url(${code})`,
            backgroundSize: "cover",
            width: "100%",
            height: "500pt"
        }}>
            <div className='d-inline-block m-auto'>
                <h1 className='display-1 text-center'>Professional Web Solutions</h1>
                <h3 className='text-center'>At Affordable Prices</h3>
            </div>
        </div>
        <main className='p-5'>

            <article className='container'>
                <div className="media-container-row">

                </div>
                <h1 className='display-3 text-center'>Need a web solution?</h1>
                <br/>
                <div>
                    <img alt="computer" src={website} className='ms-3 mb-3 float-end w-50 d-lg-inline d-none'/>
                    <p>If you want a professional, feature-rich website at a great price, you’ve come to the right
                        place.
                        Whether you’re trying to start a business, create a new website to grow your current business,
                        or even have work done on an existing website, I have you covered.</p>
                    <p>You’re placing your development in the hands of a developer with years of experience
                        and a dedication to making products you will be happy with.
                        For local customers (Northwest Ohio and surrounding areas), I also offer networking,
                        computer repairs, and on-premises server setup. </p>
                    <p>Websites are the next step in your business's success. In an increasingly
                        digital world,
                        it's almost a necessity to have a digital presence for the survival of your business.
                        Websites are a way for your business to set itself apart from the competition and allow
                        more potential customers to discover you. As your business grows, sites like Etsy become
                        less viable as you want your business to stand out more. It's time to start thinking about
                        taking your business to the next level.</p>
                    <img alt="computer" src={website} className='mb-2 w-100 d-lg-none d-inline'/>
                    <p>Get your estimate for free by <Link className='link-light' to="/contact/">contacting</Link> me.
                        Please
                        include a brief overview of the work you would like to have done in your email, text message, or
                        voicemail if applicable.</p>
                </div>
                <br style={{
                    clear: 'both'
                }}/>
                <Footer/>
            </article>
        </main>
    </>
}

export default Home;
