import {FC, useEffect} from "react";

import computer from '../assets/computer.jpg'
import Footer from "../components/Footer";

const About: FC = () => {
    useEffect(() => {
        document.title = "About | Zach Baker"
    }, []);

    return <main className='container p-5'>
        <h1 className='display-3'>About</h1>
        <p>My name is Zach Baker and I'm currently a college student at the University of Toledo.
            I am majoring in Computer Science and Engineering to help further my career. I've done basic programming
            since fourth grade and started web development in fifth grade. I continued my education throughout the
            rest of my secondary education and the month I graduated I began working as a sole proprietor.</p>
        <img src={computer} alt="computer" className='w-100 mb-2'/>
        <p>Currently I'm looking to grow my own business in web development by designing, creating, and hosting websites
            for other small businesses in Northwest Ohio and surrounding areas. I intend to use the money I make through
            this business to fund my education and reinvest into my business. I look forward to offering more affordable
            and personalized service for businesses that just can't afford the cost of hiring a large software
            development company</p>
        <p>

        </p>
        <hr/>
        <Footer/>
    </main>
}

export default About;
