import {FC, useEffect} from "react";

import phone from '../assets/phone-outline.svg';
import email from '../assets/email-outline.svg';
import Footer from "../components/Footer";

const Contact: FC = () => {
    useEffect(() => {
        document.title = "Contact | Zach Baker";
    }, []);

    return <main className="p-5">
        <article className="container">
            <div className="media-container-row">
                <h1 className='display-3 text-center'>Contact</h1>
                <hr/>
                <br/>
                <br/>
                <div className="d-flex flex-row justify-content-center">
                    <div className="p-5 flex justify-content-center text-center">
                        <h3 className="text-center">Call / Text</h3>
                        <a className='link-light' href="tel:4199721804">
                                <span className="text-muted">
                                    <img src={phone} alt='phone'/>
                                </span>
                            <br/>
                            <span>(419) 972 - 1804</span>
                        </a>
                    </div>
                    <div className="p-5 flex justify-content-center text-center">
                        <h3 className="text-center">Email</h3>
                        <a className='link-light' href="mailto:zach@zachbaker.dev">
                                <span className="text-muted">
                                    <img src={email} alt='email'/>
                                </span>
                            <br/>
                            <span>zach@zachbaker.dev</span>
                        </a>
                    </div>
                </div>
            </div>
            <hr/>
            <Footer/>
        </article>
    </main>
}

export default Contact;
