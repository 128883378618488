import {FC} from "react";

type ImageCreditProps = {
    image: {
        location: string,
        alt: string,
        link: string
    },
    author: {
        name: string,
        link: string
    },
    license: {
        name: string,
        link: string
    },
    side: "left" | "right"
}

const ImageCredit: FC<ImageCreditProps> = (props) => {
    return <div className='w-100 d-flex justify-content-between mb-5'>
        {props.side === "left" &&
            <a className='w-50' href={props.image.link}><img className='w-100' src={props.image.location} alt={props.image.alt}/></a>}
        <div style={{
            width: '45%',
            textAlign: props.side
        }} className='d-flex justify-content-center flex-column'>
            <p>Artist: <a href={props.author.link} className='link-light'>{props.author.name}</a></p>
            <p>License: <a href={props.license.link} className='link-light'>{props.license.name}</a></p>
        </div>
        {props.side === "right" &&
            <a className='w-50' href={props.image.link}><img className='w-100' src={props.image.location} alt={props.image.alt}/></a>}
    </div>
}

export default ImageCredit;
