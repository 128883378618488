import {FC} from "react";
import {Link} from "react-router-dom";

const Header: FC = () => {
    return <header>
        <nav className="navbar navbar-expand-lg p-3 bg-black">
            <Link to={'/'} className='navbar-brand'>zachbaker.dev</Link>
            <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                    <Link to={'/'} className='nav-link'>Home</Link>
                </li>
                <li className="nav-item">
                    <Link to={'/about'} className='nav-link'>About</Link>
                </li>
                <li className="nav-item">
                    <Link to={'/contact'} className='nav-link'>Contact</Link>
                </li>
            </ul>
        </nav>
    </header>
}

export default Header;
