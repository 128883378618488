import React, {FC} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import Header from "./components/Header";
import About from "./pages/About";
import Credits from "./pages/Credits";
import Contact from "./pages/Contact";

const App: FC = () => {
    return <BrowserRouter>
        <Header/>
        <Routes>
            <Route index Component={Home}/>
            <Route path='/about' Component={About}/>
            <Route path='/credits' Component={Credits}/>
            <Route path='/contact' Component={Contact}/>
        </Routes>
    </BrowserRouter>;
}

export default App;
