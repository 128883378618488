import {FC, useEffect} from "react";

import code from '../assets/code.jpg';
import computer from '../assets/computer.jpg';
import website from '../assets/website.jpg';
import ImageCredit from "../components/ImageCredit";

const Credits: FC = () => {
    useEffect(() => {
        document.title = "Credits | Zach Baker";
    }, []);

    return <main className='container p-5'>
        <h1 className='display-3 text-center'>Credits</h1>
        <hr/>
        <h1 className='display-6 text-center'>Unsplash</h1>
        <hr/>
        <ImageCredit image={{
            location: code,
            link: "https://unsplash.com/photos/captcha-cvBBO4PzWPg",
            alt: "code"
        }} author={{
            link: "https://unsplash.com/@markusspiske",
            name: "Markus Spiske"
        }} license={{
            link: "https://unsplash.com/license",
            name: "Unsplash License"
        }} side="left"/>
        <ImageCredit image={{
            location: computer,
            link: "https://unsplash.com/photos/black-and-silver-laptop-computer-on-table-95YRwf6CNw8",
            alt: "computer"
        }} author={{
            link: "https://unsplash.com/@clemhlrdt",
            name: "Clément Hélardot"
        }} license={{
            link: "https://unsplash.com/license",
            name: "Unsplash License"
        }} side={"right"}/>
        <ImageCredit image={{
            location: website,
            link: "https://unsplash.com/photos/computer-screen-displaying-website-home-page-Ylk5n_nd9dA",
            alt: "website"
        }} author={{
            link: "https://unsplash.com/@pankajpatel",
            name: "Pankaj Patel"
        }} license={{
            link: "https://unsplash.com/license",
            name: "Unsplash License"
        }} side="left"/>
    </main>
}

export default Credits;
